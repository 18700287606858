<template>
    <div class="dashboard">
        <div class="content">
            <router-view></router-view>
        </div>
        <PhoneMenu />
    </div>
</template>

<script>
import PhoneMenu from "./PhoneMenu.vue";
export default {
    name: "brokerLayouts",
    components: { PhoneMenu },
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.dashboard {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content {
        width: 100%;
        max-width: 600px;
        min-height: 100vh;
    }
}
</style>
